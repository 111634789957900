import { useEffect } from 'react';
import HK from 'factory/modules/HK';
import SkeletonPattern from 'utility/templates/skeletons/SkeletonPattern';
import PageLayout from 'components/Assets/Layouts/PageLayout';

export default function ErrorLayout(props) {
    useEffect(() => {
        if (!props.show404) {
            setTimeout(() => {
                HK.errorPopup("Something went wrong. Please try again.");
HK.reportErrorToDashboard({
        stackTrace: new Error().stack,
      });
            }, 1000);
        }
    }, [])
    return (
        <PageLayout
            isMobile={props.isMobile}
            pageType={"500"}
            {...props}
        >
            {
                props.isMobile ? <SkeletonPattern pattern={[2, 2, 2]} /> : <div className="HK-pagecontainer"><SkeletonPattern pattern={[2, 2, 2]} /></div>
            }
        </PageLayout>
    )
}