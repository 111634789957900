import ErrorLayout from "components/Assets/Layouts/ErrorLayout";
import { isMobileSSR, isLoggedInSSR } from 'factory/utils/serverUtils';
import { isLogedInAction } from 'factory/store/actions/';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

function Error({ statusCode, isMobile, serverIsLoggedin, _isLogedInAction }) {
    if (serverIsLoggedin) {
        _isLogedInAction(serverIsLoggedin);
      }
    if(statusCode){
        console.log("SERVER ERROR STATUS::: ", statusCode);
    }
    return (
        <ErrorLayout show404={statusCode === 404} isMobile={isMobile}/>
    )
  }
  
  Error.getInitialProps = ({ req, res, err }) => {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404
    let isMobile = isMobileSSR(req);
    let serverIsLoggedin = isLoggedInSSR(req);
    return { statusCode, isMobile, serverIsLoggedin }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      _isLogedInAction: isLogedInAction
    }, dispatch);
  }
  
  export default connect(null, mapDispatchToProps)(Error);