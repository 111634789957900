export const isLogedInAction = (val)=>{
    return{
        type:"IS_LOGEDIN_ACTION",
        payload:val
    };
};

export const isAssumeAction = (val)=>{
    return{
        type:"IS_ASSUME_ACTION",
        payload:val
    };
};

export const searchAction = (val)=>{
    return{
        type:"SEARCH_ACTION",
        payload:val
    };
};

export const isReferEarnPopupShownAction = (val)=>{
    return{
        type:"IS_REFER_EARN_POPUP_SHOWN",
        payload:val
    };
};

export const compareItemCountAction = (val)=>{
    return{
        type:"COMPARE_ITEM_COUNT_ACTION",
        payload:val
    };
};

export const comparePopupAction = (val)=>{
    return{
        type:"COMPARE_POPUP_ACTION",
        payload:val
    };
};

export const tbRedeemPopUpAction = (val) => {
    return {
        type: "TB_REDEEM_POPUP_ACTION_SHOW",
        payload: val
    };
};

export const tbReferAndEarnTnCAction = (val) => {
    return {
        type: "TB_REFER_AND_EARN_TNC_ACTION",
        payload: val
    };
};

export const tbConcernNameAction = (val) => {
    return {
        type: "TB_CONCERN_NAME_ACTION",
        payload: val
    };
};

export const tbUserTabAction = (val) => {
    return {
        type: "TB_USER_TAB_ACTION",
        payload: val
    };
};

export const tbsCategoriesAction = (val) => {
    return {
        type: "TBS_CATEGORIES_ACTION",
        payload: val
    };
};
export const userDetailAction = (val)=>{
    return{
        type:"USER_DETAIL_ACTION",
        payload:val
    };
};